<template>
    <div>
      <div id="step-3-content" class="container">
        <div class="row">
          <div class="row col-12 p-0 m-0 mt-4">
            <div class="col-12 text-left">
              <h5 class="m-0 mt-3 dark-blue-text font-weight-bold">
                デジタルリノベーション内容を入力してください。
              </h5>
            </div>
          </div>
        </div>
        <b-form-checkbox-group>

          <div class="img-area">
            <div>
              <button
                class="btn btn-dt dark-blue-outline dark-blue-text"
                style=""
                v-waves.light
                v-on:click="openFloorSample()"
                >
                <div class="drawing-area">
                    <span style="margin-left: 10px;">床・壁・天井の素材変更のサンプルをみる</span>
                </div>
              </button>
            </div>
            <div>
              <button
                class="btn btn-dt dark-blue-outline dark-blue-text"
                style=""
                v-waves.light
                v-on:click="openOutSample()"
                >
                <div class="drawing-area">
                    <span style="margin-left: 10px;">外壁・屋根の素材変更のサンプルをみる</span>
                </div>
                
              </button>
            </div>
          </div>
          <div id="item-list">
            <div
              class="row photo-details-item"
              v-for="(el, i) in staging.items"
              :key="i"
            >
              <div
                class="col-12 col-md-12 col-lg-12 col-xl-4 position-relative p-0"
              >
                <div class="photo" style="padding:15px;">
                  <img
                    :src="el.file_url"
                    @click="openModal(el.file_url)"
                    onerror="this.src='/img/image-error.png'"
                  />
                </div>
              </div>
              <div class="col-20 col-md-20 col-lg-20 col-xl-8"> 
                <div class="list">
                  <div class="col-12" style="padding-right:0px;padding-left: 0px;">
                    <div v-if="materialCount[el.id] < 10">
                      <div class="d-flex" style="width:100%;">
                        <input
                          :id="'material-uploader_'+ el.id"
                          type="file"
                          class="form-control"
                          accept=".jpeg,.jpg,.png,.pdf"
                          v-on:change="validatePdfs(el.id, $event)"
                          multiple
                          hidden
                        />
                        <div class="col-20 img-upload-box test" style="align-items: center;">
                            <div style="display:flex;height: 50px;">
                              <div>
                                <button
                                class="btn btn-dt dark-blue-outline dark-blue-text"
                                style=""
                                v-waves.light
                                v-on:click="setAddPdf(materialCount[el.id], el.id)"
                                :disabled="
                                    materialCount[el.id] > 10 ? true : false
                                "
                                >
                                <div class="drawing-area" v-if="(uploadCount > 0 || loaderItems.length > 0) && loadingItemId == el.id">
                                  <div>
                                    <b-spinner style="height: 18px; width: 18px"></b-spinner>
                                    <span style="margin-left: 10px;margin-right:10px;" class="img-btn">アップロード中 ({{ uploadCount }})</span>
                                    </div>
                                </div>
                                <div v-else class="drawing-area">
                                    <font-awesome-icon class="icon" :icon="{ prefix: 'fas', iconName: 'file-arrow-up' }"/>
                                    <span v-if="materialCount[el.id] == 0" style="margin-left: 10px;" class="img-btn">サンプル素材・完成イメージ資料をアップロード</span>
                                    <span v-else class="img-btn">{{ materialCount[el.id] }}件アップロード済み</span>
                                </div>
                                
                                </button>
                              </div>
                              <div class="text-right" style="margin-left: -24px;align-items: center;">
                                <button
                                class="btn btn-dt dark-blue-outline dark-blue-text"
                                style="width: 80px;"
                                v-waves.light
                                :disabled="
                                    materialCount[el.id] > 0 ? false : true
                                "
                                v-on:click="delPdf(materialCount[el.id], el.id)"
                                >
                                <div class="drawing-area" style="background:#055ab8; color:#fff">
                                    <span class="img-btn">削除</span>
                                </div>
                                </button>
                              </div>
                            </div>
                            <div class="col-18" style="padding-left:15px;padding-top:15px;">
                                <div class="">
                                    <p class="m-0">
                                    <span class="mr-5 font-weight-bold dark-blue-text">
                                        追加オプション
                                    </span>
                                    <b-spinner
                                        class="grey-text"
                                        small
                                        v-if="all_additionalOptions.loader"
                                    ></b-spinner>
                                    </p>
                                    <div class="mt-1" v-if="all_additionalOptions.loader">
                                    <b-skeleton class="mb-2" animation="wave"></b-skeleton>
                                    <b-skeleton class="mb-2" animation="wave"></b-skeleton>
                                    <b-skeleton class="mb-2" animation="wave"></b-skeleton>
                                    <b-skeleton class="mb-2" animation="wave"></b-skeleton>
                                    </div>
                                    <b-form-group v-else>
                                    <b-form-checkbox-group
                                        v-model="additionalOptions[i].value"
                                        name="additional-options"
                                    >
                                        <div class="item-font">
                                        <div
                                            class="item"
                                            style="font-size:13px;"
                                            v-for="(el, i) in all_additionalOptions.options"
                                            :key="i"
                                        >
                                            <b-form-checkbox
                                            class="grey-text step2"
                                            :value="el.value"
                                            >
                                            {{ el.text }} - {{ formatPrice(el.price) }}
                                            </b-form-checkbox>
                                        </div>
                                        </div>
                                    </b-form-checkbox-group>
                                    </b-form-group>
                                </div>
                            </div>
                        </div>
                        
                        
  
                      </div>
                    </div>
                    <div v-if="materialCount[el.id] > 0">
                      <div class="d-flex">
                        <input
                          id="material-uploader"
                          type="file"
                          class="form-control"
                          accept=".jpeg,.jpg,.png,.pdf"
                          v-on:change="validatePdfs(el.id, $event)"
                          multiple
                          hidden
                        />
                        
                      </div>
                    </div>

                  </div>
                  <div class="col-12">
                    <div class="">
                        <span class="mr-2 font-weight-bold dark-blue-text">
                        物件名/号室
                        </span>
                        <b-form-input
                        v-model="property_name[i].value"
                        class="form-input"
                        placeholder="ご自由に記入ください。"
                        ></b-form-input>
                    </div>
                    
                    <span class="mr-2 font-weight-bold dark-blue-text">コメント</span>
                    <b-form-textarea
                      v-model="remarks[i].value"
                      class="form-area"
                      placeholder="以下の特殊な記号は使えません。 !@#$%^&*()[]"
                      rows="5"
                      max-rows="5"
                      @input="comments()"
                    ></b-form-textarea>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </b-form-checkbox-group>
        <b-alert
          class="m-0 mt-5 light-grey-outline grey-text"
          variant="warning"
          show
          v-if="formMessage !== null"
        >
          {{ formMessage }}
        </b-alert>
      </div>
  
      <b-modal
        id="step2-remove-photo"
        class="border-radius-2"
        size="md"
        hide-footer
        hide-header
        no-close-on-backdrop
        no-close-on-esc
        centered
      >
        <h5
          class="grey-text dark-blue-text-hover float-right pl-1 pr-1"
          v-on:click="$bvModal.hide('step2-remove-photo')"
        >
          <font-awesome-icon :icon="{ prefix: 'fas', iconName: 'times' }" />
        </h5>
        <div class="container text-center">
          <img class="mt-4" src="/img/icons/ico-info.svg" alt="ico-info" />
          <h5 class="mt-4 mb-0 dark-blue-text font-weight-bold">
            この依頼を削除してもよろしいですか？
          </h5>
          <div class="row mt-5 pl-5 pr-5 pb-3">
            <div class="col-12 col-md-6 col-lg-6 col-xl-6">
              <button
                class="btn btn-ds light-grey white-text pt-2 pb-2"
                type="button"
                v-waves.light
                v-on:click="$bvModal.hide('step2-remove-photo')"
              >
                キャンセル
              </button>
            </div>
            <div class="col-12 col-md-6 col-lg-6 col-xl-6">
              <button
                class="btn btn-ds dark-blue white-text pt-2 pb-2"
                type="button"
                v-waves.light
                v-on:click="setRemovePhoto"
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </b-modal>

      <b-modal
        id="floor-sample"
        class="border-radius-2"
        size="lg"
        hide-footer
        hide-header
        no-close-on-backdrop
        no-close-on-esc
        centered
      >
        <h5
          class="grey-text dark-blue-text-hover float-right pl-1 pr-1"
          v-on:click="closeFloorSample"
        >
          <font-awesome-icon :icon="{ prefix: 'fas', iconName: 'times' }" />
        </h5>
        <div class="container text-center">
          <p class="mt-4 mb-0 dark-blue-text font-weight-bold text-size">
            床・壁・天井の素材変更のサンプルをみる
          </p>
          <div class="img-area">
            <div class="img1">
              <img
                class="shadow-1 border-radius-1 mb-4"
                src="/img/staging/digireno_before.JPG"
                alt="upload-modal-model-1"
              />
              <p class="sample-text ml-3 mt-2 dark-blue-text font-weight-bold">
                Before
              </p>
            </div>

            <div class="img2">
              <img
                class="shadow-1 border-radius-1"
                src="/img/staging/digireno_after.jpg"
                alt="upload-modal-model-1"
              />
              <p class="sample-text ml-3 mt-2 dark-blue-text font-weight-bold">
                After
              </p>
            </div>
          </div>
          <div class="img-area">
            <div class="img1">
              <img
                class="shadow-1 border-radius-1 mb-4"
                src="/img/staging/digireno_before_floor.JPG"
                alt="upload-modal-model-1"
              />
              <p class="sample-text ml-3 mt-2 dark-blue-text font-weight-bold">
                Before
              </p>
            </div>

            <div class="img2">
              <img
                class="shadow-1 border-radius-1"
                src="/img/staging/digireno_after_floor.jpg"
                alt="upload-modal-model-1"
              />
              <p class="sample-text ml-3 mt-2 dark-blue-text font-weight-bold">
                After
              </p>
            </div>
          </div>
        </div>
      </b-modal>

      <b-modal
        id="out-sample"
        class="border-radius-2"
        size="lg"
        hide-footer
        hide-header
        no-close-on-backdrop
        no-close-on-esc
        centered
      >
        <h5
          class="grey-text dark-blue-text-hover float-right pl-1 pr-1"
          v-on:click="closeOutSample"
        >
          <font-awesome-icon :icon="{ prefix: 'fas', iconName: 'times' }" />
        </h5>
        <div class="container text-center">
          <p class="mt-4 mb-0 dark-blue-text font-weight-bold text-size">
            外壁・屋根の素材変更のサンプルをみる
          </p>
          <div class="img-area">
            <div class="img1">
              <img
                class="shadow-1 border-radius-1 mb-4"
                src="/img/staging/digireno_before_out_1.JPG"
                alt="upload-modal-model-1"
              />
              <p class="sample-text ml-3 mt-2 dark-blue-text font-weight-bold">
                Before
              </p>
            </div>

            <div class="img2">
              <img
                class="shadow-1 border-radius-1"
                src="/img/staging/digireno_after_out_1.jpg"
                alt="upload-modal-model-1"
              />
              <p class="sample-text ml-3 mt-2 dark-blue-text font-weight-bold">
                After
              </p>
            </div>
          </div>
          <div class="img-area">
            <div class="img1">
              <img
                class="shadow-1 border-radius-1 mb-4"
                src="/img/staging/digireno_before_out_2.jpg"
                alt="upload-modal-model-1"
              />
              <p class="sample-text ml-3 mt-2 dark-blue-text font-weight-bold">
                Before
              </p>
            </div>

            <div class="img2">
              <img
                class="shadow-1 border-radius-1"
                src="/img/staging/digireno_after_out_2.jpg"
                alt="upload-modal-model-1"
              />
              <p class="sample-text ml-3 mt-2 dark-blue-text font-weight-bold">
                After
              </p>
            </div>
          </div>
        </div>
      </b-modal>

      <image-modal @closeModal="closeModal()" v-if="isOpenModal">
        <img :src="modalImageUrl">
      </image-modal>
    </div>
  </template>
  
  <script>
  // Import Services
  import {
    cus_stagingService_GetOfficeRooms,
    cus_stagingService_GetAllFurnitures,
    cus_stagingService_GetDigitalRenovationOptions,
    cus_stagingService_GetHouseLayout,
    cus_stagingService_UpdateSRUpdateItems,
    cus_stagingService_DeleteMaterial,
    cus_stagingService_GetAllSRItems,
    cus_stagingService_DeleteSRItems,
    cus_stagingService_UpdateMaterial,
    cus_stagingService_GetOfficeFunishingStyle,
  } from '../../services/customer/staging';
  
  // Import Modal
  import ImageModal from '../../components/customer/Image-Modal.vue';
  
  // Import Vuex/Store
  import { mapGetters } from 'vuex';
  
  //import common
  import { formatPrice } from '../../common/Utility';
  
  import { globalService_GetAllPrefectures } from '../../services/global';
  
  export default {
    metaInfo() {
      return {
        title: 'Digital Staging - Staging | New Request',
      };
    },
  
    data() {
      return {
        itemSelectStatus: false,
        roomType: [],
        furnitures: [],
        additionalOptions: [],
        itemsToBeDeleted: [],
        formMessage: null,
        all_roomTypes: {
          options: [],
          loader: true,
        },
        all_furnitures: {
          options: [],
          loader: true,
        },
        all_additionalOptions: {
          options: [],
          loader: true,
        },
        remarks: [],
        loadingItemId: null,
        property_name: [],
        loaderItems: [],
        uploadCount: 0,
        materialNumber: [],
        material: [],
        materialFileName: [],
        isOpenModal: false,
        modalImageUrl: null,
        optionalValue: [],
        styleList: null,
        disp_list: [],
        materialCount: [],
        prefectures: {
          options: [],
          loader: true,
        },
        houseLayout: {
          options: [],
          loader: true,
        },
        selectPrefectures: [],
        selectHouseLayout: [],
      };
    },
  
    computed: {
      ...mapGetters(['staging']),
    },
    components: {
      'image-modal': ImageModal,
    },
  
    watch: {
      'staging.items'(newValue) {
        if (newValue.length === 0) {
          let index = this.$parent.steps.completed.indexOf(3 - 1);
          if (index > -1) {
            this.$parent.steps.completed.splice(index, 1);
          }
  
          this.$parent.steps.current = 2;
        }
      },
    },
    created() {
      this.setItemData();
      this.retievePrefectures();
      this.setHouseLayout();
      this.comments();
    },
    mounted() {
      this.getAllFurnitures();
      this.getAllRoomType();
      this.getAllAdditionalOptions();
      this.getStyles();
      console.log(this.staging);
    },
    methods: {
      formatPrice,
      getAllRoomType() {
        let _this = this;
        _this.all_roomTypes.loader = true;
        cus_stagingService_GetOfficeRooms({ token: _this.staging.accessToken })
          .then((response) => {
            let data = response.data;
  
            let roomTypeOptions = [];
            data.forEach((el) => {
              roomTypeOptions.push({
                value: el.id,
                text: el.name,
              });
            });
            _this.all_roomTypes.options = roomTypeOptions;
            _this.all_roomTypes.loader = false;
          })
          .catch((error) => {
            console.log(error);
          });
      },
      getStyles() {
        cus_stagingService_GetOfficeFunishingStyle({
          token: localStorage.getItem('_accessToken')
        })
          .then((response) => {
            this.styleList = response.data
          })
          .catch((error) => {
            console.log(error);
          });
      },
      openModal(imgUrl) {
        this.modalImageUrl = imgUrl;
        this.isOpenModal = true;
      },
      closeModal(){
        this.isOpenModal = false;
      },
      checkOptional(isOptional, val) {
        let checkList = document.getElementsByName('furnitures');
        if (isOptional == 1) {
          for (let i = 0; i < checkList.length; i++) {
            if (checkList[i].value != val) {
              checkList[i].checked = false;
            }
          }
        } else {
          for (let i = 0; i < checkList.length; i++) {
            for (let j = 0; j < this.optionalValue.length; j++) {
              if (this.optionalValue[j] == checkList[i].value ) {
                checkList[i].checked = false;
              }
            }
          }
        }
      },
      getAllFurnitures() {
        let _this = this;
        _this.all_furnitures.loader = true;
        cus_stagingService_GetAllFurnitures({ token: _this.staging.accessToken })
          .then((response) => {
            let data = response.data;
  
            let furnituresOptions = data.map((el) => {
              if (el.is_optional == 1) {
                _this.optionalValue.push(el.id);
              }
              return {
                text: el.name,
                value: el.id,
                room_area_id: el.room_area_id,
                is_optional: el.is_optional
              };
            });
            _this.all_furnitures.options = furnituresOptions;
            _this.all_furnitures.loader = false;
  
            _this.setFurnitureData();
          })
          .catch((error) => {
            console.log(error);
          });
      },
      getAllAdditionalOptions() {
        let _this = this;
        _this.all_additionalOptions.loader = true;
        cus_stagingService_GetDigitalRenovationOptions({
          token: _this.staging.accessToken,
        })
          .then((response) => {
            let data = response.data;
            let additionalOptions = data.map((el) => {
              return {
                text: el.name,
                value: el.id,
                price: el.charge,
              };
            });
            _this.all_additionalOptions.options = additionalOptions;
            _this.all_additionalOptions.loader = false;
          })
          .catch((error) => {
            console.log(error);
          });
      },
      setItemData() {
        let _this = this;
  
        _this.staging.items.forEach((el) => {
          _this.materialCount[el.id] = el.material_count ? el.material_count : 0;
          //set each item room type values
          _this.roomType.push({
            itemID: el.id,
            value: el.room_area_id ? el.room_area_id : null,
          });
          console.log('roomType');
          console.log(_this.roomType);
  
          //set each item furniture values
          let funituresValue = el.furnitures.map((_el) => {
            return _el.furniture_id;
          });
          _this.furnitures.push({
            itemID: el.id,
            value: funituresValue,
            options: [],
            loader: true,
          });
  
          //set each item options
          let setAdditionalOptionsValue = el.additional_options.map((_el) => {
            return _el.additional_option_id;
          });
          _this.additionalOptions.push({
            itemID: el.id,
            value: setAdditionalOptionsValue,
          });
  
          // set remarks
          _this.remarks.push({
            itemID: el.id,
            value: el.remarks ? el.remarks : null,
          });
  
          // set property
          _this.property_name.push({
            itemID: el.id,
            value: el.property_name ? el.property_name : null,
          });
  
          _this.disp_list.push({
            itemID: el.id,
            value: el.disp_list ? el.disp_list : 0,
          });
  
          _this.selectPrefectures.push({
            itemID: el.id,
            value: el.prefecture_id ? el.prefecture_id : null,
          });
  
          _this.selectHouseLayout.push({
            itemID: el.id,
            value: el.house_layout_id ? el.properthouse_layout_idy_name : null,
          });
        });
        this.$forceUpdate();
      },
      setFurnitureData() {
        let _this = this;
        _this.staging.items.forEach((el, i) => {
          _this.filterFurnitures(el.room_area_id, el.id);
        });
      },
      filterFurnitures(type, item_id) {
        let _this = this;
        let filtered_furnitures = _this.all_furnitures.options.filter(
          (e) => e.room_area_id === type
        );
  
        const index = _this.furnitures.findIndex((object) => {
          return object.itemID === item_id;
        });
        //set furniture options base on room type
        _this.furnitures[index].options = filtered_furnitures;
        _this.furnitures[index].loader = false;
      },
      setItemSelectStatus(e) {
        let itemListCheckBox = document.querySelectorAll(
          '#item-list .custom-control-input'
        );
        if (e) {
          this.itemsToBeDeleted = this.staging.items.map((_) => _.id);
  
          itemListCheckBox.forEach((el) => {
            el.setAttribute('checked', true);
          });
        } else {
          this.itemsToBeDeleted = [];
  
          itemListCheckBox.forEach((el) => {
            el.removeAttribute('checked');
          });
        }
      },
  
      setRemovePhotoModal() {
        this.formMessage = null;
  
        if (this.itemsToBeDeleted.length > 0) {
          this.$bvModal.show('step2-remove-photo');
        } else {
          this.formMessage = 'Please select photos to be deleted.';
        }
      },
  
      setRemovePhoto() {
        let _this = this;
  
        _this.$bvModal.hide('step2-remove-photo');
  
        _this.$store.state.modalLoaderMessage = '削除中';
        _this.$bvModal.show('modal-loader');
  
        cus_stagingService_DeleteSRItems({
          item_ids: _this.itemsToBeDeleted,
          token: _this.staging.accessToken,
        })
          .then(() => {
            _this.$store.state.staging.items = _this.staging.items.filter(
              (el) => {
                if (!_this.itemsToBeDeleted.includes(el.id)) {
                  return el;
                }
              }
            );
  
            setTimeout(() => {
              _this.$bvModal.hide('modal-loader');
            }, 500);
          })
          .catch((error) => {
            console.log(error);
  
            _this.$bvToast.toast(
              'アップロード中にエラーが起こりました。再度お試しください。',
              {
                title: 'システムエラー',
                autoHideDelay: 5000,
                variant: 'danger',
                appendToast: true,
              }
            );
  
            setTimeout(() => {
              _this.$bvModal.hide('modal-loader');
            }, 500);
          });
      },
      async updateItemData() {
          let _this = this;
          _this.formMessage = null;

          _this.$store.state.modalLoaderMessage = '料金を計算中';
          _this.$bvModal.show('modal-loader');

          let items = _this.roomType.map((roomType) => {
              let { itemID } = roomType;
              let furniture = _this.furnitures.find((_) => _.itemID === itemID);
              let additionalOptions = _this.additionalOptions.find(
              (_) => _.itemID === itemID
              );
              let remarks = _this.remarks.find((_) => _.itemID === itemID);
              let property_name = _this.property_name.find((_) => _.itemID === itemID);

              return {
                  item_id: itemID,
                  room_area_id: roomType.value,
                  furnitures: furniture.value,
                  additional_options: additionalOptions.value,
                  remarks: remarks.value,
                  property_name: property_name.value,
                  disp_list: 0,
                  prefectures: null,
                  house_layout: null,
              };
          });

          let validationErrors = [];
          items.forEach((el, i) => {
              let itemNumber = i + 1;

              if (el.room_area_id === null) {
              validationErrors.push(itemNumber);
              }
          });

          if (validationErrors.length === 0) {
              console.log(items);
              await cus_stagingService_UpdateSRUpdateItems({
                  items: items,
                  token: _this.staging.accessToken,
              })
              .then((response) => {
                  cus_stagingService_GetAllSRItems({
                  id: _this.staging.info.id,
                  paginated: 0,
                  page_size: 0,
                  page: 0,
                  token: _this.staging.accessToken,
                  })
                  .then((_response) => {
                      let stagingItems = _response.data.result;

                      _this.$store.state.staging.items = stagingItems;

                      this.$parent.steps.completed.push(2);
                      this.$parent.steps.current = 3;

                      setTimeout(() => {
                      _this.$bvModal.hide('modal-loader');
                      setTimeout(() => {
                          _this.$parent.scrollTop();
                      }, 250);
                      }, 500);
                  })
                  .catch((_error) => {
                      console.log(_error);

                      localStorage.removeItem('_digitalRenovationStagingRequestID');

                      setTimeout(() => {
                      _this.$bvModal.hide('modal-loader');
                      }, 500);
                  });
              })
              .catch((error) => {
                  console.log(error);

                  _this.formMessage =
                  '依頼の更新中にエラーが起こりました。再度お試しください。';

                  setTimeout(() => {
                  _this.$bvModal.hide('modal-loader');
                  }, 500);
              });
          } else {
              _this.formMessage = `お部屋のタイプが必要です。 ${validationErrors.join(
              ', '
              )}.`;

              setTimeout(() => {
              _this.$bvModal.hide('modal-loader');
              _this.$parent.scrollTop();
              }, 500);
          }
      },
      setAddPdf(count, id) {
          if (count < 10) {
          let idName = 'material-uploader_' + id;
          let fileUploaders = document.querySelector('#' + idName);
  
          fileUploaders.value = null;
          fileUploaders.click();
        } else {
          this.formMessage =
            '<li>Maximum of 10 images only. Please remove image to add a new one.</li>';
        }
      },
      delPdf(count, id) {
        if (count > 0) {
  
          cus_stagingService_DeleteMaterial({
          item_id: id,
          sr_id: this.staging.info.id,
          token: this.staging.accessToken,
        })
          .then(() => {
            this.materialCount[id] = 0;
            this.$forceUpdate();
            setTimeout(() => {
              this.$bvModal.hide('modal-loader');
            }, 500);
          })
          .catch((error) => {
            console.log(error);
  
            this.$bvToast.toast(
              '削除中にエラーが起こりました。再度お試しください。',
              {
                title: 'システムエラー',
                autoHideDelay: 5000,
                variant: 'danger',
                appendToast: true,
              }
            );
  
            setTimeout(() => {
              this.$bvModal.hide('modal-loader');
            }, 500);
          });
        }
      },
      validatePdfs(id, e) {
        let _this = this;
  
        _this.formMessage = null;
        _this.loadingItemId = id;
  
        let files = e.target.files;
        let filesErrorMessage = [];
  
        _this.uploadCount = files.length;
  
        if (files.length > 0) {
          if (files.length <= 20) {
            for (let i = 0; i < files.length; i++) {
              
              if (
                files[i].type === 'image/jpeg' ||
                files[i].type === 'image/png' ||
                files[i].type === 'application/pdf'
              ) {
                if (files[i].size <= 10000000) {
                  console.log('Processing ...');
                } else {
                  filesErrorMessage.push(
                    `ファイル名 (${files[i].name}) - 画像は 10 MB 未満である必要があります。`
                  );
  
                  _this.$parent.steps.proceedDisabled = false;
                  _this.uploadCount = 0;
                }
              } else {
                filesErrorMessage.push(
                  `<li>File Name (${files[i].name}) - Please upload a file type of JPEG, JPG or PNG or PDF.</li>`
                );
  
                _this.uploadCount = 0;
              }
            }
            
            setTimeout(() => {
              _this.validatePdfsProceed(_this, files, filesErrorMessage, id);
            }, 1000);
          } else { 
            _this.uploadCount = 0;
          }
        }
      },
      validatePdfsProceed(_this, files, filesErrorMessage, item_id) {
        if (filesErrorMessage.length === 0) {
          for (let i = 0; i < files.length; i++) {
            _this.loaderItems.push('-' + i);
            _this.materialNumber.push(_this.loaderItems.length + i)
            let formData = new FormData();
            formData.append('sr_id', _this.staging.info.id);
            formData.append('item_id', item_id);
  
            if(files[i].type === 'image/jpeg' || files[i].type === 'image/png' ) {
              formData.append('img', files[i]);
            } else if(files[i].type === 'application/pdf') {
              formData.append('pdf', files[i]);
            }
            formData.append('index', _this.materialNumber.length);
  
            cus_stagingService_UpdateMaterial(
              {
                token: _this.staging.accessToken,
              },
              formData
            )
              .then((response) => {
                if (response.data.result) {
                  _this.loaderItems.pop();
                  _this.material.push(response.data.result);
                  this.materialFileName = response.data.result
                  this.materialCount[item_id] = this.materialCount[item_id] + 1;
                  this.$forceUpdate();
                }
                _this.uploadCount -= 1;
                _this.loadingItemId = null;
              })
              .catch((error) => {
                _this.uploadCount = 0;
                _this.formMessage = error;
                console.log(error);
              });
          }
        } else {
          _this.formMessage = filesErrorMessage.join('');
          _this.uploadCount = 0;
        }
      },
      retievePrefectures() {
        let _this = this;
  
        _this.prefectures.loader = true;
  
        let accessToken = localStorage.getItem('_accessToken');
  
        globalService_GetAllPrefectures({ token: accessToken })
          .then((response) => {
            let data = response.data;
  
            let prefectures = [];
  
            data.forEach((el) => {
              prefectures.push({
                value: el.id,
                text: el.name,
              });
            });
  
            _this.prefectures.options = prefectures;
  
            _this.prefectures.loader = false;
          })
          .catch((error) => {
            console.log(error);
          });
      },
      setHouseLayout() {
        let accessToken = localStorage.getItem('_accessToken');
        cus_stagingService_GetHouseLayout({ token: accessToken })
          .then((response) => {
            let data = response.data;
  
            let houseLayout = [];
  
            data.forEach((el) => {
              houseLayout.push({
                value: el.id,
                text: el.name,
              });
            });
  
            this.houseLayout.options = houseLayout;
  
            this.houseLayout.loader = false;
          })
          .catch((error) => {
            console.log(error);
          });
      },
      comments() {
        for(let i = 0; i < this.remarks.length; i++) {
            console.log(this.remarks[i]);
            if (this.remarks[i].value == null || this.remarks[i].value == '') {
                this.$parent.steps.proceedDisabled = true;
                return;
            }
            this.$parent.steps.proceedDisabled = false;
        }
      },
      openFloorSample() {
        this.$bvModal.show('floor-sample');
      },
      openOutSample() {
        this.$bvModal.show('out-sample');
      },
      closeFloorSample() {
        this.$bvModal.hide('floor-sample');
      },
      closeOutSample() {
        this.$bvModal.hide('out-sample');
      }
    },
  };
  </script>
  
  <style scoped>
  /* Init Style */
  /* Custom Style */
  .room-type-select {
    background-color: #ffffff !important;
  }
  
  #step-3-content .photo-details-item {
    margin: 40px 0 0 0;
    box-shadow: 0px 0px 25px #00000029;
    border-radius: 15px;
    background: #f5f5f5;
  }
  
  #step-3-content .photo-details-item .photo {
    padding: 0;
    height: 100%;
    width: 100%;
    max-height: 345px;
  }
  
  @media only screen and (max-width: 1440px) {
    #step-3-content .photo-details-item .photo {
      /* padding: 14px; */
    }
  }
  
  @media only screen and (min-width: 1200px) {
    .layout {
      max-width:50%;
    }
  }
  
  @media only screen and (min-width: 1200px) {
    .comment {
      width:300px;
    }
  }
  @media only screen and (min-width: 767px) {
    .comment {
      max-width:100%;
    }
  }
  
  @media only screen and (max-width: 767px) {
    #step-3-content .photo-details-item .photo {
      padding: 14px;
    }
  }
  
  #step-3-content .photo-details-item .photo img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 15px;
  }
  
  @media only screen and (max-width: 1440px) {
    #step-3-content .photo-details-item .photo img {
      object-fit: cover;
      border-radius: 15px;
      max-height: 245px;
    }
  }
  
  @media only screen and (max-width: 1199px) {
    #step-3-content .photo-details-item .photo img {
      object-fit: cover;
      border-radius: 15px;
    }
  }
  
  #step-3-content .photo-details-item .remove-phohoto-dsk {
    display: block;
    float: right;
    margin-top: 20px;
  }
  
  #step-3-content .photo-details-item .remove-phohoto-mbl {
    display: none;
  }
  
  @media only screen and (max-width: 1199px) {
    #step-3-content .photo-details-item .remove-phohoto-dsk {
      display: none;
    }
  
    #step-3-content .photo-details-item .remove-phohoto-mbl {
      display: block;
      position: absolute;
      top: 20px;
      right: 0;
    }
  }
  
  #step-3-content .photo-details-item .funiture-select {
    margin-top: 92px;
  }
  
  .option-select {
    margin-top: 50px;/*106px*/
  }
  
  @media only screen and (max-width: 1199px) {
    #step-3-content .photo-details-item .funiture-select {
      margin-top: 20px;
    }
  
    .option-select {
      margin-top: 20px;
    }
  }
  .btn-dt {
    font-weight: bold;
    font-size: 11px;
    border:none;
    width: 100%;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    transition: all 0.6s cubic-bezier(0.25, 0.8, 0.25, 1);
    margin-bottom: -30px;
  }
  .main-container {
    margin-top: 9px;
    display: grid;
  
  }
  .list {
    display: flex;
    width:100%;
    flex-wrap: wrap;
    margin-right: -23px;
    margin-left: 9px;
    margin-bottom: 15px;
  }
  .form-input {
    display: block;
    height: 100% !important;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
  }
  .form-area {
    display: block; 
    height: calc(1.5em + 5.75rem + 10px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
  }
  .drawing-area {
    border:1px solid #055ab8;
    background: #fff;
    border-radius:6px;
    padding:7px;
    margin-left: 3px;
  }
  .select-furniture {
      display: flex;
      flex-wrap: wrap;
      margin-right: -15px;
      margin-left: -15px;
  
  }
  .test {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
  }
  .img-area {
    display: flex;
    justify-content: center;
    margin-top: 15px;
    }
  .img1 {
    margin: 0 5px;
    position:relative;
  }
  .img2 {
    margin: 0 5px;
    position:relative;
  }
  .sample-text{
    position: absolute;
    top:0;
    left:5;
  }
  </style>
  